export namespace Als {
  export const clean = (chunk: string) => {
    const index = chunk.indexOf('"ELEMENT"');
    let strippedChunk = chunk.substring(index);
    strippedChunk = strippedChunk.replace(/"SAMPLE.*(\r|\n)+/, "");
    const tailIndex = strippedChunk.indexOf("Please");
    strippedChunk = strippedChunk.slice(0, tailIndex).trim();
    return strippedChunk;
  };
}
