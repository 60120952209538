import type { Animal } from "../models/animal";
import { ApiBase } from "./apiBase";

class AnimalApi extends ApiBase {
  async fetchForCustomer(customerId: number, baseAnimal: string) {
    return this.get<Animal[]>("animal/for_customer", {
      customer_id: customerId,
      base_animal: baseAnimal,
    });
  }
}

export default new AnimalApi();
