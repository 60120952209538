import type { Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    green: Palette["primary"];
  }

  interface PaletteOptions {
    green?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    green: true;
  }
}

export const addGreen = (theme: Theme) =>
  theme.palette.augmentColor({
    color: { main: "#00b74a" },
    name: "green",
  });
