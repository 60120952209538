import { hasPermission } from "../helpers/authHelpers";
import { UserContext } from "../context/userContext";
import { useContext } from "react";

const useCurrentUser = () => {
  const { currentUser } = useContext(UserContext);
  return currentUser;
};

export const useAuthenticated = () => {
  const authenticated = !!useCurrentUser();
  return authenticated;
};

export const useHasPermission = (permission: string) => {
  const permissions = usePermissions();
  return hasPermission(permission, permissions);
};

export const usePermissions = () => {
  return useCurrentUser()?.permissions ?? {};
};
