import { Box, CircularProgress, CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import * as Sentry from "@sentry/react";
import { Settings } from "luxon";
import * as React from "react";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ErrorBoundary } from "./components/app/errorBoundary";
import { MainLayout } from "./components/app/mainLayout";
import { browserItems } from "./helpers/navigationHelpers";
import store from "./reducers/singletonStore";
import { ColorModeContext, useTheme } from "./views/theme";
import { ApplicationProvider } from "./context/applicationContext";
import { UserProvider } from "./context/userContext";
import { ProductProvider } from "./context/productContext";
Settings.defaultLocale = "sv";

const reloadOnError = (event: Event & { payload?: { message: string } }) => {
  if (!event.payload) {
    return
  };
  console.log(event);
  const retried = sessionStorage.getItem(`errRetries${event.payload.message}`);
  if (retried) {
    return;
  }
  sessionStorage.setItem(`errRetries${event.payload.message}`, '1');
  window.location.reload();
}

window.addEventListener('vite:preloadError', (event) => {
  reloadOnError(event);
})

const Main: React.FC = () => {
  const theme = useTheme();
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <ColorModeContext.Provider value={theme.colorMode}>
              <ThemeProvider theme={theme.theme}>
                <LocalizationProvider
                  dateAdapter={AdapterLuxon}
                  adapterLocale="sv"
                >
                  <ErrorBoundary>
                    <UserProvider>
                      <ApplicationProvider>
                        <Box
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                            backgroundColor: (theme) =>
                              theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                            height: "100vh",
                            overflow: "auto",
                          }}
                        >
                          <Suspense fallback={<CircularProgress />}>
                            <CssBaseline />
                            <ProductProvider>
                              <RouterProvider
                                router={router}
                                future={{ v7_startTransition: true }}
                              />
                            </ProductProvider>
                          </Suspense>
                        </Box>
                      </ApplicationProvider>
                    </UserProvider>
                  </ErrorBoundary>
                </LocalizationProvider>
              </ThemeProvider>
            </ColorModeContext.Provider>
          </StyledEngineProvider>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    element: <MainLayout />,
    children: browserItems().concat({
      path: "*",
      element: <Navigate to="/t/registration" replace />,
    }),
  },
]);

Sentry.init({
  dsn: "https://a954050c85994e8290ee1a0f597e45e2@o113422.ingest.sentry.io/249795",
  environment: import.meta.env.DEV ? "development" : "production",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: import.meta.env.DEV ? 0 : 0.2,
});

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<Main />);
