import type { Alert } from "../models/alert";
import type { AnalysisGroup } from "../models/analysisGroup";
import type { Message } from "../models/message";
import type { IReadAnalysisPoco } from "../models/pocos/readAnalysisPoco";
import { ApiBase } from "./apiBase";

interface Analysis {
  labnumber: string;
  weight: number;
}

export interface Bubble {
  id: number;
  bubbleName: string;
  locked?: boolean;
}

export interface ResultStatus {
  status: string;
  labnumber: string;
  weight: number;
  msgs: string[];
  messages: Alert[];
  totalWeight: number;
  count: number;
  notes: Message[];
  customerNotes: Message[];
}
export interface BubbleResult {
  bubble: Bubble;
  analyses: Analysis[];
  result?: ResultStatus;
}

class BubbleApi extends ApiBase {
  async open(bubbleNumber: string) {
    return this.post<BubbleResult>("bubble/open", {
      bubble_number: bubbleNumber,
    });
  }
  async load(bubbleNumber: number) {
    return this.get<BubbleResult>("bubble/get", {
      bubble_number: bubbleNumber,
    });
  }
  async close(bubbleId: number, labnumbers: string[]) {
    return this.post<Bubble>("bubble/close", {
      bubble_id: bubbleId,
      labnumbers: labnumbers,
    });
  }
  async bubble(bubbleId: number, labnumber: number, labnumbers: string[]) {
    return this.post<BubbleResult>("bubble/bubble", {
      bubble_id: bubbleId,
      labnumber: labnumber,
      labnumbers: labnumbers,
    });
  }

  async fetch(bubbleNumber: string, analysisGroupType: string) {
    return this.get<IReadAnalysisPoco>("journals/inmatning_bubble", {
      id: bubbleNumber,
      analysis_group_type: analysisGroupType,
    });
  }

  async fetchBubble(bubbleNumber: number | undefined, animalType: string) {
    return this.get<AnalysisGroup[]>("journals/filters", {
      bubble_number: bubbleNumber,
      animal_type: animalType,
    });
  }
}

export default new BubbleApi();
