import type { Veterinarian } from "../models/veterinarian";
import { ApiBase } from "./apiBase";

class VeterinarianApi extends ApiBase {
  async load() {
    return this.get<Veterinarian[]>("veterinarian/get");
  }

  async create(veterinarian: Veterinarian) {
    return this.post<Veterinarian>("veterinarian/create", { veterinarian });
  }

  async update(veterinarian: Veterinarian) {
    return this.post<Veterinarian>("veterinarian/update", { veterinarian });
  }
}

export default new VeterinarianApi();
