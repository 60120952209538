import * as Formulas from "./analysisFormulas";
import { type Analysis, initialize } from "./models/analysis";
import type { ValidationResult } from "./models/framework/validationResult";

export function calculateAnalyses(
  rawAnalyses: readonly Analysis[],
  otherAnalyses: readonly Analysis[],
  initializeEmpty = false,
) {
  let analyses = rawAnalyses;
  if (initializeEmpty) {
    analyses = analyses.map((a) => (a.raw == null ? initialize(a) : a));
  }
  analyses = analyses.map((analysis) => {
    const { value, ...details } = calculate(analysis, analyses, otherAnalyses);
    return {
      ...analysis,
      calc: value,
      details: { ...analysis.details, ...details },
    };
  });

  // TODO: Currently needs to calculate twice to propagate blocked
  return analyses.map((analysis) => {
    const { value, ...details } = calculate(analysis, analyses, otherAnalyses);
    return {
      ...analysis,
      calc: value,
      details: { ...analysis.details, ...details },
    };
  });
}

function toFixed(result: { value: number; blocked?: boolean }, digits: number) {
  return { ...result, value: result.value.toFixed(digits) };
}

function roundDownToTen(result: { value: number; blocked?: boolean }) {
  let rounded = Math.floor(result.value / 10) * 10;
  if (rounded === 0 && result.value > 0) {
    rounded = 5;
  }
  return { ...result, value: rounded };
}

// eslint-disable-next-line complexity
function calculate(
  analysis: Analysis,
  analyses: readonly Analysis[],
  otherAnalyses: readonly Analysis[],
) {
  switch (analysis.meta?.formula) {
    case "dryweight":
      return toFixed(Formulas.dryWeight(analysis, analyses), 1);
    case "dryweight2":
      return toFixed(Formulas.dryWeightCorr(analysis, analyses), 2);
    case "spaceweight":
      return toFixed(Formulas.spaceweight(analysis, analyses), 1);
    case "drysubst":
      return toFixed(Formulas.drysubst(analysis, analyses), 2);
    case "drysubst_spa":
      return toFixed(Formulas.drysubstSpa(analysis, analyses), 2);
    case "g/kg_drysubst":
      return toFixed(Formulas.gPerkgDrysubst(analysis, analyses), 2);
    case "aat_g/kg":
      return toFixed(Formulas.aatgPerkg(analysis, analyses), 2);
    case "nfe":
      return toFixed(Formulas.nfe(analysis, analyses), 2);
    case "nfe_drysubst":
      return toFixed(Formulas.nfeDrysubst(analysis, analyses), 2);
    case "nfe_g/kg_drysubst":
      return toFixed(Formulas.nfegPerkgDrysubst(analysis, analyses), 2);
    case "smb_g/kg":
      return toFixed(Formulas.smbgPerkg(analysis, analyses), 2);
    case "pbv_g/kg":
      return toFixed(Formulas.pbvgPerkg(analysis, analyses), 2);
    case "oms_nrg":
      return toFixed(Formulas.omsEnergy(analysis, analyses), 2);
    case "aatGrov":
      return toFixed(Formulas.aatGrov(analysis, analyses), 2);
    case "pbvGrov":
      return toFixed(Formulas.pbvGrov(analysis, analyses), 2);
    case "QCaP":
      return toFixed(Formulas.QuotaCaP(analysis, analyses), 2);
    case "omsEnergyHest":
      return toFixed(Formulas.omsEnergyHest(analysis, analyses), 2);
    case "omsEnergyIdis":
      return toFixed(Formulas.omsEnergyIdis(analysis, analyses), 2);
    case "smbProtGrov":
      return toFixed(Formulas.smbProtGrov(analysis, analyses), 2);
    case "smbCarbGrov":
      return toFixed(Formulas.smbCarbGrov(analysis, analyses), 2);
    case "protEnergyQuota":
      return toFixed(Formulas.protEnergyQuota(analysis, analyses), 2);
    case "sugar":
      return toFixed(Formulas.sugar(analysis, analyses), 2);
    case "glucose":
      return toFixed(Formulas.glucose(analysis, analyses), 2);
    case "fructose":
      return toFixed(Formulas.fructose(analysis, analyses), 2);
    case "fructan":
      return toFixed(Formulas.fructan(analysis, analyses), 2);
    case "sucrose":
      return toFixed(Formulas.sucrose(analysis, analyses), 2);
    case "pcr":
      return toFixed(Formulas.pcr(analysis, analyses), 0);
    case "cab":
      return toFixed(Formulas.cab(analysis, analyses, otherAnalyses), 2);
    case "scp":
      return toFixed(Formulas.scp(analysis, analyses, otherAnalyses), 2);
    case "nh4n":
      return toFixed(Formulas.nh4n(analysis, analyses, otherAnalyses), 2);
    case "omd":
      return toFixed(Formulas.omd(analysis, analyses, otherAnalyses), 2);
    case "butyricacid":
      return toFixed(
        Formulas.butyricacid(analysis, analyses, otherAnalyses),
        2,
      );
    case "percent":
      return roundDownToTen(Formulas.percent(analysis, analyses));
    case "epg":
      return Formulas.epg(analysis, analyses);
    case "ostertagia":
      return toFixed(Formulas.ostertagia(analysis, analyses), 2);
    case "elisaCtrl":
      return toFixed(Formulas.elisaCtrl(analysis, analyses), 2);
    default:
      if (analysis.type === "percent") {
        return roundDownToTen(Formulas.percent(analysis, analyses));
      } else {
        return Formulas.epg(analysis, analyses);
      }
  }
}

export function radioValue(analysis: Analysis) {
  return analysis.raw == null ? null : analysis.raw ? "1" : "0";
}

export function validate(
  analysis: Analysis,
  analyses: readonly Analysis[],
): ValidationResult {
  let valid = true;
  let validationMessage: string | undefined;
  if (analysis.max && analysis.raw) {
    if (analysis.raw > analysis.max) {
      valid = false;
      validationMessage = "Observera! Högt äggantal";
    }
  }
  if (analysis.meta?.warningLimits && analysis.raw) {
    if (
      analysis.raw < +analysis.meta.warningLimits[0] ||
      analysis.raw > +analysis.meta.warningLimits[1]
    ) {
      valid = false;
      validationMessage = `Utanför gränsvärden [${analysis.meta.warningLimits[0]} - ${analysis.meta.warningLimits[1]}]`;
    }
  }
  if (analysis.type === "percent" || analysis.meta?.formula === "percent") {
    const percent = Formulas.percent(analysis, analyses);
    if (percent.value !== undefined && percent.value > 100) {
      valid = false;
      validationMessage = ">100%";
    }
    if (analysis.percentOf) {
      const total = analyses
        .filter((a) => a.percentOf === analysis.percentOf)
        .map((a) => (a ? Formulas.percent(a, analyses)?.value || 0 : 0))
        .reduce<number>((a, b) => a + b, 0);
      if (total > 100) {
        valid = false;
        validationMessage = ">100% totalt";
      }
    }
  }
  if (
    analysis.meta?.lowerlimit &&
    analysis.raw &&
    analysis.raw < analysis.meta.lowerlimit
  ) {
    valid = false;
    validationMessage = "Utanför giltigt intervall";
  }

  return { valid, validationMessage };
}
