export namespace Icp {
  export const clean = (chunk: string) => {
    const rows = chunk.split("\n");
    const firstRow = rows[0].split(",");
    const secondRow = rows[1].split(",");
    for (let i = 0; i < firstRow.length; i++) {
      firstRow[i] = firstRow[i] || secondRow[i];
    }
    rows.splice(0, 2);
    rows.unshift(firstRow.join(","));
    return rows.join("\n");
  };

  export const cleanHeader = (header: string) => {
    return header.replace(/[\d]+\s+(.*)\s+\[.*\]/, "$1").trim();
  };
}
