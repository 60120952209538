import type { DetailedJournalStatus } from "../models/detailedJournalStatus";
import { ApiBase } from "./apiBase";

class PauseApi extends ApiBase {
  async pause(labnumbers: readonly (string | number)[]) {
    return this.post<DetailedJournalStatus[]>("pause/pause", { labnumbers });
  }
  async unfinished() {
    return this.get<DetailedJournalStatus[]>("pause/unfinished");
  }
}

export default new PauseApi();
