import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import invoiceApi from "../apis/invoiceApi";
import type { IInvoice } from "../models/invoice";

interface GodInvoiceSliceState {
  invoices: IInvoice[];
  summary: { [analysis: string]: number };
  loading: boolean;
}

const initialState: GodInvoiceSliceState = {
  invoices: [],
  summary: {},
  loading: false,
};

type GodInvoiceOptions = { from: string; tom: string };

export const loadGodInvoices = createAsyncThunk(
  "godInvoice/load",
  (options: GodInvoiceOptions) => {
    return invoiceApi.loadGod(options);
  },
);

const godInvoiceSlice = createSlice({
  name: "godinvoice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(loadGodInvoices.pending, (state, action) => {
        state.loading = true;
        state.invoices = [];
        state.summary = {};
      })
      .addCase(loadGodInvoices.rejected, (state, action) => {
        state.loading = false;
        state.invoices = [];
        state.summary = {};
      })
      .addCase(loadGodInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.invoices = action.payload.invoices;
        state.summary = action.payload.summary;
      }),
});

export default godInvoiceSlice.reducer;
