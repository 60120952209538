import type { AnalysisTypeMeta } from "./analysisType";
import type { IProduct } from "./product";

export interface AnalysisGroupMeta {
  selectedFor?: { [product: string]: boolean };
  invoiceFor?: { [product: string]: boolean };
  disableRat?: boolean;
}

export interface AnalysisType {
  id: number;
  analysis_group_id: number;
  name: string;
  code: string;
  numeric: boolean;
  boolean: boolean;
  dependency: string;
  dependencyType: string;
  extra: boolean;
  default: number;
  dependencyCode: string;
  multiple: number;
  max: number;
  percent: boolean;
  percentOf: string;
  meta?: AnalysisTypeMeta;
  canHide: boolean;
  defaultHidden: boolean;
  reportName: string;
  reportSortKey: number;
}

export interface IAnalysisGroup {
  id: number;
  code: string;
  createdAt?: string;
  followsGroup: null;
  force: boolean;
  grouped: boolean;
  hidden: boolean;
  extra: boolean;
  invoice: boolean;
  line: null;
  meta: AnalysisGroupMeta;
  mutexGroup: null;
  name: string;
  reportName?: string;
  reportSortKey: number;
  requireScan: boolean;
  requiredPrel: boolean;
  selected: boolean;
  sortKey: number;
  updatedAt?: string;
  enabled?: boolean;
  analysisTypes: AnalysisType[];
}

export function selected(analysisGroup: IAnalysisGroup, product: IProduct) {
  if (analysisGroup.meta?.selectedFor) {
    if (
      Object.hasOwn(
        <{ [productType: string]: boolean }>analysisGroup.meta.selectedFor,
        product.typeName,
      )
    ) {
      return (<{ [productType: string]: boolean }>(
        analysisGroup.meta.selectedFor
      ))[product.typeName];
    }
  }
  return analysisGroup.selected;
}

export function invoice(analysisGroup: IAnalysisGroup, product: IProduct) {
  if (analysisGroup.meta?.invoiceFor) {
    if (
      Object.hasOwn(
        <{ [productType: string]: boolean }>analysisGroup.meta.invoiceFor,
        product.typeName,
      )
    ) {
      return (<{ [productType: string]: boolean }>(
        analysisGroup.meta.invoiceFor
      ))[product.typeName];
    }
  }
  return analysisGroup.invoice;
}

export type AnalysisGroup = Readonly<IAnalysisGroup>;
