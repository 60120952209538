import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import registrationApi from "../apis/registrationApi";
import type { Registration } from "../models/registration";

interface PreregistrationSliceState {
  registration: Registration | undefined;
  loading: boolean;
}

const initialState: PreregistrationSliceState = {
  registration: undefined,
  loading: false,
};

export const fetchPregistration = createAsyncThunk(
  "preregistration/fetch",
  async (registrationId: number) => {
    return registrationApi.fetchPreregistration(registrationId);
  },
);

const preregistrationSlice = createSlice({
  name: "preregistration",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchPregistration.pending, (state, _action) => {
        state.loading = true;
        state.registration = undefined;
      })
      .addCase(fetchPregistration.fulfilled, (state, action) => {
        state.loading = false;
        state.registration = action.payload;
      })
      .addCase(fetchPregistration.rejected, (state, _action) => {
        state.loading = false;
        state.registration = undefined;
      }),
});

export default preregistrationSlice.reducer;
