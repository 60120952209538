import type { Analysis, IAnalysis } from "../analysis";

type keys = "id" | "raw" | "type" | "details" | "hidden" | "name" | "calc";

export class SaveAnalysisPoco implements Pick<IAnalysis, keys> {
  id: IAnalysis["id"];
  raw?: IAnalysis["raw"];
  calc?: IAnalysis["calc"];
  // tslint:disable-next-line:no-reserved-keywords
  type: IAnalysis["type"];
  // note: IAnalysis["note"];
  details: IAnalysis["details"];
  hidden: IAnalysis["hidden"];
  name: IAnalysis["name"];
  static FromAnalysis(analysis: Analysis) {
    const poco = new SaveAnalysisPoco();
    poco.id = analysis.id;
    poco.raw = analysis.raw;
    poco.calc = analysis.calc;
    poco.type = analysis.type;
    poco.details = analysis.details;
    poco.hidden = analysis.hidden;
    poco.name = analysis.name;
    return poco;
  }
}
