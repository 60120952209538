import type { DateTime } from "luxon";
import type { IProductFamily } from "./productFamily";

export interface AnimalMeta {
  [key: string]: string | DateTime | boolean | number | undefined;
}

export interface IAnimal {
  id: number;
  name: string;
  customerId?: number;
  animalType?: string;
  baseAnimal?: IProductFamily;
  avmaskad?: DateTime;
  meta?: AnimalMeta;
}

export type Animal = Readonly<IAnimal>;

export function readAnimalMeta<K extends keyof AnimalMeta>(
  animal: Animal,
  key: K,
): AnimalMeta[K] {
  return animal.meta?.[key];
}
