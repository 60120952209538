import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { Notification } from "../models/framework/notification";
import { DateTime } from "luxon";

interface NotificationSliceState {
  notifications: Notification[];
  history: Notification[];
}

const initialState: NotificationSliceState = {
  notifications: [],
  history: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addSuccess(state, action: PayloadAction<string>) {
      const notification: Notification = {
        severity: "success",
        message: action.payload,
        autoClose: 3600,
        id:
          (state.notifications
            .map((n) => n.id)
            .sort()
            .reverse()[0] ?? 0) + 1,
        createdAt: DateTime.local().toISO(),
      };
      state.notifications.push(notification);
    },
    addError(state, action: PayloadAction<string>) {
      const notification: Notification = {
        severity: "error",
        message: action.payload,
        id:
          (state.notifications
            .map((n) => n.id)
            .sort()
            .reverse()[0] ?? 0) + 1,
        createdAt: DateTime.local().toISO(),
      };
      state.notifications.push(notification);
    },
    addWarning(state, action: PayloadAction<string>) {
      const notification: Notification = {
        severity: "warning",
        message: action.payload,
        id:
          (state.notifications
            .map((n) => n.id)
            .sort()
            .reverse()[0] ?? 0) + 1,
        createdAt: DateTime.local().toISO(),
      };
      state.notifications.push(notification);
    },
    addInfo(state, action: PayloadAction<string>) {
      const notification: Notification = {
        severity: "info",
        message: action.payload,
        autoClose: 3600,
        id:
          (state.notifications
            .map((n) => n.id)
            .sort()
            .reverse()[0] ?? 0) + 1,
        createdAt: DateTime.local().toISO(),
      };
      state.notifications.push(notification);
    },
    close(state, action: PayloadAction<Notification>) {
      const notification = action.payload;
      state.notifications = state.notifications.filter(
        (n) => n.id !== notification.id,
      );
      state.history = [notification, ...state.history]
        .sort(
          (a, b) =>
            DateTime.fromISO(b.createdAt).toSeconds() -
            DateTime.fromISO(a.createdAt).toSeconds(),
        )
        .slice(0, 10);
    },

    closeAll(state) {
      state.history = [...state.notifications.reverse(), ...state.history]
        .sort(
          (a, b) =>
            DateTime.fromISO(b.createdAt).toSeconds() -
            DateTime.fromISO(a.createdAt).toSeconds(),
        )
        .slice(0, 10);
      state.notifications = [];
    },
  },
});

export const { addSuccess, addError, addWarning, addInfo, close, closeAll } =
  notificationSlice.actions;

export default notificationSlice.reducer;
