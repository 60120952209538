import type { Analysis, IAnalysis } from "../models/analysis";
import type { AnalysisGroup } from "../models/analysisGroup";
import type { IAnalysisType } from "../models/analysisType";
import type {
  IReadAnalysisBatchPoco,
  IReadAnalysisPoco,
} from "../models/pocos/readAnalysisPoco";
import { SaveAnalysisPoco } from "../models/pocos/saveAnalysisPoco";
import type { IStatus } from "../models/status";
import { ApiBase } from "./apiBase";

class ReadAnalysisApi extends ApiBase {
  async getFilters(labnumber: number) {
    return this.get<AnalysisGroup[]>("journals/filters", { labnr: labnumber });
  }

  async getAnalyses(labnumber: number, analysisType: string) {
    return this.get<IReadAnalysisPoco>("journals/inmatning", {
      id: labnumber,
      analysis_group_type: analysisType,
    });
  }

  async getAnalysesBatch2(labnumbers: number[]) {
    return this.post<IReadAnalysisBatchPoco>("journals/batch_read2", {
      ids: labnumbers,
    });
  }

  async getPlateAnalyses(platenumber: string, wells: string[]) {
    return this.post<IReadAnalysisBatchPoco>("journals/plate_read", {
      platenumber,
      wells,
    });
  }

  async getExtras(labnumber: number, analysisType: string) {
    return this.get<IAnalysisType[]>("journals/get_extras", {
      labnr: labnumber,
      analysis_group_type: analysisType,
    });
  }

  async addAnalysis(labnumber: number, analysisType: number) {
    return this.post<{ analyses: IAnalysis[] }>("journals/add_analysis", {
      labnr: labnumber,
      analysis_type: analysisType,
    });
  }

  async saveAnalyses(
    analyses: {
      labnumber: number;
      analyses: readonly Analysis[];
      internalNote?: string;
      externalNote?: string;
      pending?: boolean;
    }[],
    fromBatch: boolean,
  ) {
    const analysesToSave = analyses.map((row) => {
      return {
        labnumber: row.labnumber,
        analyses: row.analyses.map((a) => SaveAnalysisPoco.FromAnalysis(a)),
        note: row.internalNote,
        customerNote: row.externalNote,
        pending: row.pending,
      };
    });
    return this.post<IStatus[]>("journals/batch_save", {
      results: analysesToSave,
      fromBatch,
    });
  }
}

export default new ReadAnalysisApi();
