import type { Customer } from "../models/customer";
import type { JournalGroup } from "../models/journalGroup";
import type { MailJob } from "../models/mailJob";
import {
  type IProductFamily,
  readProductFamilyMeta,
} from "../models/productFamily";
import type { Veterinarian } from "../models/veterinarian";
import { arrayInsert } from "./arrayHelpers";

export function getMailJobs(
  customer: Customer,
  journalGroup: JournalGroup,
  veterinarian?: Veterinarian,
  baseAnimal?: IProductFamily,
  reset?: boolean,
) {
  const customerJob2 = customer.email2
    ? {
        selected: true,
        email: customer.email2,
        key: "customerEmail2",
      }
    : undefined;
  const customerJob1 = customer.email
    ? {
        selected: !customerJob2 || customerJob2.email === customer.email,
        email: customer.email,
        key: "customerEmail",
      }
    : undefined;
  const veterinarianJob = veterinarian?.email
    ? {
        selected: false,
        email: veterinarian.email,
        key: "veterinarianEmail",
      }
    : undefined;

  let jobs: readonly MailJob[] = journalGroup.mailJobs || [];
  if (reset) {
    jobs = resetJobs(jobs);
  }
  const extraEmailMeta = readExtraEmailMeta(baseAnimal);
  if (extraEmailMeta) {
    let index = 0;
    for (const extraEmail of extraEmailMeta) {
      const conditions: string[] = extraEmail.condition;
      let lookupObject: Customer | JournalGroup; // = customer;
      if (extraEmail.lookup === "JournalGroup") {
        lookupObject = journalGroup;
      } else {
        lookupObject = customer;
      }
      let found = true;
      for (const condition of conditions) {
        if (!lookupObject) {
          break;
        }
        // @ts-ignore
        lookupObject = lookupObject[condition];
        if (!lookupObject) {
          found = false;
        }
      }
      if (found && lookupObject === extraEmail.value) {
        for (const receiver of extraEmail.receivers) {
          const job = {
            selected: true,
            email: receiver,
            key: `extra${index}`,
            attachmentType: extraEmail.type,
            onlyFinal: extraEmail.onlyFinal,
          };
          jobs = addJob(jobs, job, job.key);
          index++;
        }
      }
    }
  }

  jobs = addJob(jobs, customerJob1, "customerEmail");
  jobs = addJob(jobs, customerJob2, "customerEmail2");
  jobs = addJob(jobs, veterinarianJob, "veterinarianEmail");

  return jobs;
}

function readExtraEmailMeta(product: IProductFamily | undefined) {
  if (!product) {
    return undefined;
  }

  return readProductFamilyMeta(product, "extraMail");
}

function addJob(
  jobs: readonly MailJob[],
  job: MailJob | undefined,
  key: string,
) {
  if (job && jobs.find((j) => j.email === job.email)) {
    return jobs;
  }
  const index = jobs.findIndex((j) => j.key === key);
  if (job && index < 0) {
    return jobs.concat([job]);
  } else if (job) {
    return arrayInsert(jobs, { ...jobs[index], email: job.email }, index);
  }
  return jobs;
}

function resetJobs(jobs: readonly MailJob[]) {
  return jobs.filter(
    (j) => j.key !== "customerEmail" && j.key !== "customerEmail2",
  );
}

export function mailStatusTranslation(mailStatus: string) {
  if (mailStatus === "success") {
    return "lyckades";
  } else if (mailStatus === "timeout") {
    return "misslyckades efter timeout";
  } else if (mailStatus === "fail_no_mail") {
    return "misslyckades, ingen mail inlagd";
  } else {
    return "misslyckades";
  }
}
