import type { ExtraField } from "./extraField";

export interface IProduct {
  id: number;
  baseAnimalId: number;
  designation: string;
  displayName: string;
  multiple: boolean;
  prefix: string;
  typeName: string;
  metaFields?: {
    fields?: { [key: string]: ExtraField };
    registrationRequiresAnimal?: boolean;
  };
}

export function extraProductFields(product: IProduct | undefined) {
  return product?.metaFields?.fields || {};
}
