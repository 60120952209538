import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import checkApi from "../apis/checkApi";
import type { LineCheckResult } from "../models/types/lineCheck";

interface LineCheckSliceState {
  checking: boolean;
  checkResult?: LineCheckResult;
}

const initialState: LineCheckSliceState = {
  checking: false,
};
export const checkLine = createAsyncThunk(
  "lineCheck/check",
  async (labnumber: number) => {
    return checkApi.check(labnumber);
  },
);

const lineCheckSlice = createSlice({
  name: "lineCheck",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkLine.pending, (state) => {
        state.checking = true;
      })
      .addCase(checkLine.fulfilled, (state, action) => {
        state.checking = false;
        state.checkResult = action.payload;
      })
      .addCase(checkLine.rejected, (state) => {
        state.checking = false;
      });
  },
});

// export const {
// } = lineCheckSlice.actions;

export default lineCheckSlice.reducer;
