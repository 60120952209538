import { createSvgIcon } from "@mui/material";
export const Genetic = createSvgIcon(
  <svg viewBox="0 -960 960 960" fill="currentColor">
    <title>Genetics</title>
    <path d="M200-40v-40q0-140 65-226t169-174q-104-88-169-174t-65-226v-40h60v40q0 11 .5 20.5T262-840h436q1-10 1.5-19.5t.5-20.5v-40h60v40q0 140-65 226T526-480q104 88 169 174t65 226v40h-60v-40q0-11-.5-20.5T698-120H262q-1 10-1.5 19.5T260-80v40h-60Zm120-640h320q16-23 27.5-47.5T687-780H273q8 28 19.5 52.5T320-680Zm160 161q31-26 59-50.5t52-50.5H369q24 26 51.5 50.5T480-519ZM369-340h222q-24-26-52-50.5T480-441q-31 26-59 50.5T369-340Zm-96 160h414q-8-28-19.5-52.5T640-280H320q-16 23-27.5 47.5T273-180Z" />
  </svg>,
  "Genetics",
);

export const Antibody = createSvgIcon(
<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
  <title>Antibody</title>
  <path d="M95.812 29.781a18.002 18.002 0 0 0-12.54 30.91l81.636 81.637-14.543 14.543-73.637-73.637a18.002 18.002 0 0 0-12.916-5.453 18.002 18.002 0 0 0-12.54 30.91l96 96a18.002 18.002 0 1 0 25.456-25.457l-9.636-9.637 14.543-14.543L206 183.418v280.545a18.002 18.002 0 1 0 36 0v-199h28v199a18.002 18.002 0 1 0 36 0V183.418l28.365-28.364 14.543 14.543-9.637 9.637a18.002 18.002 0 1 0 25.457 25.457l96-96a18.002 18.002 0 0 0-13.082-30.902 18.002 18.002 0 0 0-12.375 5.445l-73.636 73.637-14.543-14.543 81.636-81.637a18.002 18.002 0 0 0-13.082-30.902 18.002 18.002 0 0 0-12.375 5.445L270 168.508v78.455h-28v-78.455L108.728 35.234a18.002 18.002 0 0 0-12.916-5.453z"/>
</svg>,
"Antibody",
)