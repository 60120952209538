export type AlertStatus = "success" | "warning" | "danger";
export type AlertSeverity = "success" | "info" | "warning" | "error";
export class Alert {
  type?: AlertStatus;
  severity?: AlertSeverity;
  msg: string;
  message: string;
  color?: string;
}

export function createAlert(
  message: string,
  status: AlertStatus | AlertSeverity | undefined,
): Alert {
  let alertStatus: AlertStatus | undefined;
  let alertSeverity: AlertSeverity;
  switch (status) {
    case "success":
      alertStatus = alertSeverity = "success";
      break;
    case "warning":
      alertStatus = alertSeverity = "success";
      break;
    case "danger":
    case "error":
      alertStatus = "danger";
      alertSeverity = "error";
      break;
    default:
      alertStatus = undefined;
      alertSeverity = "info";
  }
  return {
    type: alertStatus,
    severity: alertSeverity,
    msg: message,
    message,
  };
}
