import type { Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    orchid: Palette["primary"];
  }

  interface PaletteOptions {
    orchid?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    orchid: true;
  }
}

export const addOrchid = (theme: Theme) =>
  theme.palette.augmentColor({
    color: { main: "#ba68c8" },
    name: "orchid",
  });
