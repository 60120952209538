import type { Audit } from "../models/audit";
import { ApiBase } from "./apiBase";

class AuditApi extends ApiBase {
  async fetch<T>(entityType: string, id: number) {
    return this.get<Audit<T>[]>(`audits/${entityType}`, { id: id });
  }
}

export default new AuditApi();
