import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import animalApi from "../apis/animalApi";
import type { Animal } from "../models/animal";
import type { IProductFamily } from "../models/productFamily";

interface AnimalRegistrationSliceState {
  animals: Animal[];
  loading: boolean;
  activeCustomerId: number | undefined;
}

const initialState: AnimalRegistrationSliceState = {
  animals: [],
  loading: false,
  activeCustomerId: undefined,
};

export const fetchAnimalsForCustomer = createAsyncThunk(
  "animalRegistration/fetchAnimals",
  async (payload: { customerId: number; productFamily: IProductFamily }) => {
    const animals = await animalApi.fetchForCustomer(
      payload.customerId,
      payload.productFamily.typeName,
    );
    return { customerId: payload.customerId, animals };
  },
);

const animalRegistrationSlice = createSlice({
  name: "animalRegistration",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchAnimalsForCustomer.pending, (state) => {
        state.loading = true;
        state.activeCustomerId = undefined;
        state.animals = [];
      })
      .addCase(fetchAnimalsForCustomer.fulfilled, (state, action) => {
        state.activeCustomerId = action.payload.customerId;
        state.loading = false;
        state.animals = action.payload.animals;
      }),
});

export default animalRegistrationSlice.reducer;
