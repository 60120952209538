import type { DisposeResult, LineCheckResult } from "../models/types/lineCheck";
import { ApiBase } from "./apiBase";

class CheckApi extends ApiBase {
  async check(labnumber: number) {
    return this.get<LineCheckResult>("journals/line_checker", { labnumber });
  }

  async setBagNumber(labnumber: number, bagnumber: number) {
    return this.post<DisposeResult>("journals/dispose", {
      labnumber,
      bagnumber,
    });
  }

  async checkBagNumber(labnumber: number) {
    return this.get<DisposeResult>("journals/check_disposal", { labnumber });
  }
}

export default new CheckApi();
