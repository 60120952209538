import type { ThemeOptions } from "@mui/material";
import { svSE } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { svSE as gridSvSE } from "@mui/x-data-grid/locales";
import { svSE as dateSvSE } from "@mui/x-date-pickers/locales";
import React from "react";
import { type TernaryDarkMode, useTernaryDarkMode } from "usehooks-ts";
import { getPalette } from "../colors/colorHelper";

const palette = (darkMode: boolean) => {
  const palette: ThemeOptions["palette"] = {
    mode: darkMode ? "dark" : "light",
  };
  if (window.location.hostname === "lims.jockeman.se") {
    palette.primary = {
      main: "#117511",
    };
  }

  return palette;
};

const baseTheme = (darkMode: boolean): ThemeOptions => ({
  palette: palette(darkMode),
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

export const ColorModeContext = React.createContext({
  setDarkMode: (mode: TernaryDarkMode) => {},
  darkMode: false,
});

export const useTheme = () => {
  const { isDarkMode, setTernaryDarkMode } = useTernaryDarkMode();
  const realDarkMode = isDarkMode; // && false;
  const theme = React.useMemo(() => {
    const t = createTheme(baseTheme(realDarkMode), gridSvSE, dateSvSE, svSE);
    return createTheme(t, {
      palette: getPalette(t),
    });
  }, [realDarkMode]);
  const colorMode = React.useMemo(
    () => ({
      setDarkMode: setTernaryDarkMode,
      darkMode: realDarkMode,
    }),
    [realDarkMode, setTernaryDarkMode],
  );
  return { theme, colorMode };
};
