import {
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import * as downloadjs from "downloadjs";
import { DateTime } from "luxon";
import alsCheckApiService from "../apis/alsCheckApiService";
import type { Alert } from "../models/alert";
import type { Journal } from "../models/alsJournal";
import type { RootState } from "../reducers/rootReducer";
import { addSuccess } from "./notificationSlice";

interface CheckState {
  isFetching: boolean;
  labnumber?: number;
  alert?: Alert;
  remaining?: { labnumber: string }[];
}
interface SendState {
  isFetching: boolean;
  date?: DateTime;
  journals: Journal[];
}

interface AgrilabSliceState {
  check: CheckState;
  send: SendState;
}

const initialState: AgrilabSliceState = {
  check: { isFetching: false },
  send: { isFetching: false, journals: [] },
};

export const checkAgrilab = createAsyncThunk(
  "agrilab/check",
  async (labnumber: number) => {
    return alsCheckApiService.check(labnumber);
  },
);

export const sendAgrilab = createAsyncThunk(
  "agrilab/send",
  async (date: DateTime) => {
    return alsCheckApiService.toSend(date);
  },
);

export const generateAgrilab = createAsyncThunk<
  void,
  DateTime,
  { state: RootState }
>("agrilab/generate", async (date: DateTime, thunkApi) => {
  const journals = thunkApi.getState().agrilab.send.journals;
  const labnumbers = journals
    .filter((j) => j.send)
    .map((j) => j.labnumber.toString());
  const result = await alsCheckApiService.generate(labnumbers, date);
  const blob = await result.blob();
  downloadjs(blob, `agrilab_blankett_${DateTime.local().toISODate()}.xlsx`);
  thunkApi.dispatch(
    addSuccess(
      "Orderblankett har genererats. Är det fler än 23 prov som ska skickas så behöver ytterligare blanketter skapas.",
    ),
  );
  await thunkApi.dispatch(sendAgrilab(DateTime.local()));
});

const agrilabSlice = createSlice({
  name: "agrilab",
  initialState,
  reducers: {
    selectJournal(
      state,
      action: PayloadAction<{ journal: Journal; selected: boolean }>,
    ) {
      const journal = state.send.journals.find(
        (j) => j.labnumber === action.payload.journal.labnumber,
      );
      if (journal) {
        journal.send = action.payload.selected;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(checkAgrilab.pending, (state, action) => {
        state.check.isFetching = true;
        state.check.labnumber = action.meta.arg;
      })
      .addCase(checkAgrilab.fulfilled, (state, action) => {
        state.check.isFetching = false;
        state.check.alert = action.payload.alert;
        state.check.remaining = action.payload.remaining;
      })
      .addCase(sendAgrilab.pending, (state, action) => {
        state.send.date = action.meta.arg;
        state.send.isFetching = true;
      })
      .addCase(sendAgrilab.fulfilled, (state, action) => {
        state.send.isFetching = false;
        state.send.journals = action.payload.journals;
      }),
});

export const { selectJournal } = agrilabSlice.actions;

export default agrilabSlice.reducer;
