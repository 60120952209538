import type { PingResult } from "../models/pingResult";
import { ApiBase } from "./apiBase";

class AppApi extends ApiBase {
  async ping() {
    return this.get<PingResult>("application/ping");
  }

  async stopEmail(reason: string) {
    return this.post<PingResult>("application/stop_all_mail", { reason });
  }
  async startEmail() {
    return this.post<PingResult>("application/restore_mail_stop", {});
  }
  async track(path: string) {
    return this.post<void>(
      "application/track",
      { path: path },
      { json: false },
    );
  }
}

export default new AppApi();
