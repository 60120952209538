export const smbCarbConstants = (productType: string) => {
  switch (productType.toLowerCase()) {
    case "hö":
    case "grönmassa":
      return {
        a: 1.96,
        b: 6.84,
        c: 950,
      };
    default:
      return {
        a: 31.4,
        b: 3.89,
        c: 922,
      };
  }
};

export const digestibility = (material: string) => {
  switch (material.split(" ")[0].toLocaleLowerCase()) {
    case "havre":
      return {
        protein: 80,
        fat: 88,
        fibre: 30,
        nfe: 76,
      };
    case "korn":
      return {
        protein: 76,
        fat: 75,
        fibre: 30,
        nfe: 89,
      };
    case "råg":
      return {
        protein: 80,
        fat: 60,
        fibre: 30,
        nfe: 92,
      };
    case "vete":
    case "vetekross":
      return {
        protein: 81,
        fat: 70,
        fibre: 30,
        nfe: 92,
      };
    default:
      return {
        protein: 0,
        fat: 0,
        fibre: 0,
        nfe: 0,
      };
  }
};
