import type { IInvoice, InvoiceBasis, InvoiceOptions } from "../models/invoice";
import { ApiBase } from "./apiBase";

class InvoiceApi extends ApiBase {
  loadGod(options: { from: string; tom: string }) {
    const newLocal = "invoice/god_invoices";
    return this.get<{
      invoices: IInvoice[];
      summary: { [analysis: string]: number };
    }>(newLocal, options);
  }
  async load(options: InvoiceOptions) {
    return this.get<InvoiceBasis[]>("invoice/invoice_basis", {
      options: options,
    });
  }

  async getOne(invoiceBasisId: number) {
    return this.get<InvoiceBasis>("invoice/get", { id: invoiceBasisId });
  }

  async send(invoiceBasis: InvoiceBasis[]) {
    return this.post<InvoiceBasis[]>("invoice/invoice_from_basis", {
      invoiceBasisIds: invoiceBasis.map((ib) => ib.id),
    });
  }

  async getStatus(invoices: InvoiceBasis[]) {
    return this.get<InvoiceBasis[]>("invoice/status", {
      invoiceBasisIds: invoices.map((ib) => ib.id),
    });
  }

  async updateInvoiceBasis(invoiceBasis: InvoiceBasis) {
    return this.post<InvoiceBasis>("invoice/update_invoice_basis", {
      invoiceBasis,
    });
  }

  async cancel(invoiceBasisId: number) {
    return this.post<InvoiceBasis>("invoice/cancel", { invoiceBasisId });
  }
}

export default new InvoiceApi();
