import { Button } from "@mui/material";
import * as React from "react";

interface IErrorBoundaryState {
  hasError: boolean;
  faultReason?: string;
}

export class ErrorBoundary extends React.Component<
  React.PropsWithChildren,
  IErrorBoundaryState
> {
  state: IErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, faultReason: error.name };
  }

  render() {
    if (this.state.hasError) {
      if (this.state.faultReason === "ChunkLoadError") {
        return (
          <div style={{ width: "100%" }}>
            Hoppsan, den här sidan gick inte att ladda. Troligtvis för att det
            finns en uppdaterad version av den. Klicka på knappen nedan för att
            ladda om sidan.
            <Button onClick={() => window.location.reload()}>
              Försök igen
            </Button>
          </div>
        );
      }
      return <div>Något gick fel!</div>;
    }
    return this.props.children;
  }
}
