import { Box, Skeleton, Toolbar, Typography, styled } from "@mui/material";
import MuiAppBar, {
  type AppBarProps as MuiAppBarProps,
} from "@mui/material/AppBar";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { useDocumentTitle, useLocalStorage } from "usehooks-ts";
import { useAuthenticated } from "../../hooks/authHooks";
import { useActiveLink } from "../../hooks/routeHooks";


const DarkModeSelector = React.lazy(() => import("./darkModeSelector"));
const ApplicationStatus = React.lazy(() => import("../applicationStatus"));
const InformationButton = React.lazy(() => import("./informationButton"));
const Menu = React.lazy(() => import("../menu"));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - (${theme.spacing(8)} + 1px))`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const MainLayout: React.FC = () => {
  const [menuOpen, setMenuOpen] = useLocalStorage("menu-open", true);
  const authenticated = useAuthenticated();
  const activeLink = useActiveLink();
  useDocumentTitle(`${activeLink?.label} - Digero` ?? "Digero");

  return (
    <>
      <AppBar position="fixed" open={menuOpen}>
        <Toolbar>
          <Typography sx={{ flexGrow: 1 }}>{activeLink?.label}
          <InformationButton />
          </Typography>
          <DarkModeSelector />
          <ApplicationStatus />
        </Toolbar>
      </AppBar>
      <Menu open={menuOpen} handleClose={() => setMenuOpen(!menuOpen)} />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {authenticated ? <Outlet /> : <Skeleton />}
      </Box>
    </>
  );
};
