import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import productsApi from "../apis/productsApi";
import type { AnalysisGroup } from "../models/analysisGroup";
import type { IProduct } from "../models/product";
import type { IProductFamily } from "../models/productFamily";

export interface ProductsSliceState {
  productFamilies: IProductFamily[];
  products: { [productFamilyId: number]: IProduct[] };
  productsFlat: IProduct[];
  productAnalyses: { [productId: number]: AnalysisGroup[] };
  fetchingProductFamilies: boolean;
  fetchingProducts: boolean;
  fetchingProductAnalyses: boolean;
  allLoaded: boolean;
}

const initialState: ProductsSliceState = {
  productFamilies: [],
  products: {},
  productsFlat: [],
  productAnalyses: {},
  fetchingProductFamilies: false,
  fetchingProducts: false,
  fetchingProductAnalyses: false,
  allLoaded: false,
};

export const fetchProductFamilies = createAsyncThunk(
  "products/fetchProductFamilies",
  async (_param, thunkApi) => {
    await thunkApi.dispatch(fetchProducts());
    await thunkApi.dispatch(fetchProductAnalyses());
    return productsApi.getProductFamilies();
  },
);

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    return productsApi.getProducts();
  },
);

const fetchProductAnalyses = createAsyncThunk(
  "products/fetchProductAnalyses",
  async () => {
    return productsApi.getProductFamilyAnalyses();
  },
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductAnalyses.pending, (state) => {
        state.fetchingProductAnalyses = true;
      })
      .addCase(fetchProductAnalyses.fulfilled, (state, action) => {
        state.fetchingProductAnalyses = false;
        for (const [key, productAnalyses] of Object.entries(action.payload)) {
          state.productAnalyses[+key] = productAnalyses;
        }
      })
      .addCase(fetchProductAnalyses.rejected, (state) => {
        state.fetchingProductAnalyses = false;
      })
      .addCase(fetchProducts.pending, (state) => {
        state.fetchingProducts = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.fetchingProducts = false;
        state.products = {};
        for (const product of action.payload) {
          state.products[product.baseAnimalId] ||= [];
          state.products[product.baseAnimalId].push(product);
        }
        state.productsFlat = action.payload;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.fetchingProducts = false;
      })
      .addCase(fetchProductFamilies.pending, (state) => {
        state.fetchingProductFamilies = true;
      })
      .addCase(fetchProductFamilies.fulfilled, (state, action) => {
        state.fetchingProductFamilies = false;
        state.productFamilies = action.payload;
        state.allLoaded = true;
      })
      .addCase(fetchProductFamilies.rejected, (state) => {
        state.fetchingProductFamilies = false;
      });
  },
});

export default productsSlice.reducer;
