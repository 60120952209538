import {
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import bubbleApi from "../../apis/bubbleApi";
import readAnalysisApi from "../../apis/readAnalysisApi";
import { getErrorMessage } from "../../helpers/errorHelpers";
import type { ExtraInfo } from "../../helpers/readAnalysisHelper";
import { type Alert, createAlert } from "../../models/alert";
import type { IAnalysis } from "../../models/analysis";
import type { AnalysisGroup } from "../../models/analysisGroup";
import type { IAnalysisType } from "../../models/analysisType";
import type { Message } from "../../models/message";
import type { IStatus } from "../../models/status";
import { addError } from "../notificationSlice";

type ChangeAnalysisPayload<T extends keyof IAnalysis> = {
  id: number;
  key: T;
  value: IAnalysis[T];
};

interface ReadBubbleSliceState {
  analyses: IAnalysis[];
  extraInfo: ExtraInfo[];
  internalNotes: Message[];
  externalNotes: Message[];
  newInternalNote: string;
  newExternalNote: string;
  extras: IAnalysisType[];
  hints: Alert[];
  selectedFilterId: number;
  filters: AnalysisGroup[];
  verifyReSave: boolean;
  saving: boolean;
  labnumber?: number;
  productId?: number;
}

const initialState: ReadBubbleSliceState = {
  analyses: [],
  extraInfo: [],
  internalNotes: [],
  externalNotes: [],
  newInternalNote: "",
  newExternalNote: "",
  extras: [],
  hints: [],
  selectedFilterId: -1,
  filters: [],
  verifyReSave: false,
  saving: false,
};

export const saveBubble = createAsyncThunk<
  IStatus[],
  string | undefined,
  { state: { readBubble: ReadBubbleSliceState } }
>("bubble/save", async (extraInternalNote: string | undefined, thunkApi) => {
  const state = thunkApi.getState().readBubble;
  const analyses = state.analyses;
  const labnumber = state.labnumber;
  if (!(analyses && labnumber)) {
    return thunkApi.rejectWithValue("");
  }
  try {
    return await readAnalysisApi.saveAnalyses(
      [{ analyses, internalNote: extraInternalNote, labnumber }],
      false,
    );
  } catch (error) {
    thunkApi.dispatch(addError(getErrorMessage(error)));
    return thunkApi.rejectWithValue(getErrorMessage(error));
  }
});

export const loadBubbleFilters = createAsyncThunk(
  "bubble/loadFilters",
  async (bubbleNumber: number | undefined) => {
    return bubbleApi.fetchBubble(bubbleNumber, "trikin");
  },
);

export const loadBubble = createAsyncThunk(
  "bubble/load",
  async (bubbleNumber: string, thunkApi) => {
    try {
      return await bubbleApi.fetch(bubbleNumber, "");
    } catch (error) {
      thunkApi.dispatch(addError(getErrorMessage(error)));
      return thunkApi.rejectWithValue(getErrorMessage(error));
    }
  },
);

const readBubbleSlice = createSlice({
  name: "readBubble",
  initialState,
  reducers: {
    changeInternalNote(state, action: PayloadAction<string>) {
      state.newInternalNote = action.payload;
    },
    changeExternalNote(state, action: PayloadAction<string>) {
      state.newExternalNote = action.payload;
    },
    changeFilter(state, payload: PayloadAction<number>) {
      state.selectedFilterId = payload.payload;
    },
    changeAnalysis<T extends keyof IAnalysis>(
      state: ReadBubbleSliceState,
      action: PayloadAction<ChangeAnalysisPayload<T>>,
    ) {
      const analysisIndex = state.analyses.findIndex(
        (a) => a.id === action.payload.id,
      );
      const analysis = state.analyses[analysisIndex];
      analysis[action.payload.key] = action.payload.value;
      state.analyses[analysisIndex] = analysis;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loadBubbleFilters.fulfilled, (state, action) => {
        state.filters = action.payload;
        state.selectedFilterId = state.filters[0].id;
      })
      .addCase(loadBubble.fulfilled, (state, action) => {
        const row = action.payload.rows;
        state.hints = action.payload.hints ?? [];
        if (row) {
          state.analyses = row.analyses;
          state.internalNotes = row.notes;
          state.externalNotes = row.customerNotes;
          state.labnumber = row.journalNr;
          state.extraInfo = Object.values(row.extra);
        }
      })
      .addCase(loadBubble.rejected, (state, action) => {})
      .addCase(saveBubble.pending, (state) => {
        state.saving = true;
      })
      .addCase(saveBubble.rejected, (state, action) => {
        state.saving = false;
      })
      .addCase(saveBubble.fulfilled, (state, action) => {
        state.saving = false;
        state.hints = action.payload.map((a) => createAlert(a.msg, a.status));
      }),
});

export const { changeInternalNote, changeExternalNote, changeAnalysis } =
  readBubbleSlice.actions;

// const { changeFilter } = readBubbleSlice.actions;

export default readBubbleSlice.reducer;
