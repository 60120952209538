import type { AnalysisGroup, AnalysisType } from "../models/analysisGroup";
import type { IProduct } from "../models/product";
import type { IProductFamily } from "../models/productFamily";
import type { ProductPackage } from "../models/productPackage";
import { ApiBase } from "./apiBase";

class ProductsApi extends ApiBase {
  async getProductFamilies() {
    return this.get<IProductFamily[]>("animal/get_base");
  }

  async getProducts() {
    return this.get<IProduct[]>("animal/get_types");
  }

  async getAnalyses() {
    return this.get<AnalysisGroup[]>("animal/get_analysis_types");
  }

  async getAnalysisGroups() {
    return this.get<AnalysisGroup[]>("product/analysis_groups");
  }

  async getAnalysisTypes() {
    return this.get<AnalysisType[]>("product/analysis_types");
  }

  async getProductFamilyAnalyses() {
    return this.get<{ [key: number]: AnalysisGroup[] }>(
      "journals/analysis_types",
    );
  }

  async getProductPackages() {
    return this.get<{ [key: number]: ProductPackage[] }>(
      "animal/product_packages",
    );
  }
}

export default new ProductsApi();
