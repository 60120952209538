import {
  digestibility,
  smbCarbConstants,
} from "./helpers/analysisFormulaConstants";
import { hasOwnProperty } from "./helpers/utils";
import type { Analysis } from "./models/analysis";

type FormulaResult = {
  value: number;
  blocked?: boolean;
  minlimit?: boolean;
};

type IFormula = (
  analysis: Analysis,
  analyses: readonly Analysis[],
  otherAnalyses?: readonly Analysis[],
) => FormulaResult;

function fromFormulaName(analyses: readonly Analysis[], formulaName: string) {
  return analyses.find(
    (analysis) => analysis.meta && analysis.meta.formulaName === formulaName,
  );
}

function blocking(list: (undefined | Analysis | FormulaResult)[]) {
  return list.some((element) => {
    if (!element) {
      return true;
    }
    if (hasOwnProperty(element, "details")) {
      return !!(<Analysis>element).details?.blocked;
    } else {
      return !!(<FormulaResult>element).blocked;
    }
  });
}

export const dryWeight: IFormula = (analysis, analyses) => {
  const empty = fromFormulaName(analyses, "empty");
  const dry = fromFormulaName(analyses, "dry");
  const wet = fromFormulaName(analyses, "wet");
  const blocked = blocking([empty, dry, wet]);
  if (wet?.raw && dry?.raw && empty?.raw && wet.raw !== empty.raw) {
    return {
      value: ((dry.raw - empty.raw) / (wet.raw - empty.raw)) * 100,
      blocked,
    };
  }
  return { value: 0, blocked };
};

export const dryWeightCorr: IFormula = (analysis, analyses) => {
  const dryWeight1 = fromFormulaName(analyses, "dryweight1");
  if (!dryWeight1) {
    return { value: 0, blocked: false };
  }
  const { value, blocked } = dryWeight(dryWeight1, analyses);
  const dryWeight2 = 0.95;

  let dryCorr = value * dryWeight2;
  if (analysis.productMeta?.material?.toString().toLowerCase() === "ensilage") {
    dryCorr = 0.99 * dryCorr + 1;
  }
  return { value: dryCorr, blocked };
};

export const spaceweight: IFormula = (analysis, analyses) => {
  const cupWeight = 50;
  if (!analysis.raw || analysis.raw < cupWeight) {
    return { value: 0 };
  }
  const volWeight = analysis.raw - cupWeight;
  return { value: volWeight / 0.205 };
};

export const drysubst: IFormula = (analysis, analyses) => {
  const baseCode = analysis.meta?.base_formula_name;
  const waterweight = fromFormulaName(analyses, "vattenhalt");
  const percentStuff = fromFormulaName(analyses, <string>baseCode);
  const blocked =
    blocking([waterweight, percentStuff]) || !!analysis.details?.blocked;
  if (waterweight && percentStuff?.raw && waterweight !== percentStuff) {
    const dry = 100 - (waterweight.raw || 0);
    return { value: (percentStuff.raw / dry) * 100, blocked };
  }
  return { value: 0, blocked };
};

export const drysubstSpa: IFormula = (analysis, analyses) => {
  const moisture = fromFormulaName(analyses, "moisture");
  const blocked = blocking([moisture]);
  if (!moisture?.raw) {
    return { value: 0, blocked: true };
  }
  return { value: 100 - moisture.raw, blocked };
};

export const gPerkgDrysubst: IFormula = (analysis, analyses) => {
  const dry = drysubst(analysis, analyses);
  return { value: dry.value * 10, blocked: dry.blocked };
};

export const nfeDrysubst: IFormula = (analysis, analyses) => {
  const fatAnalysis = fromFormulaName(analyses, "fat");
  const proteinAnalysis = fromFormulaName(analyses, "protein");
  const ashAnalysis = fromFormulaName(analyses, "ash");
  const fiberAnalysis = fromFormulaName(analyses, "fibre");
  if (
    fatAnalysis?.raw != null &&
    proteinAnalysis?.raw != null &&
    ashAnalysis?.raw != null &&
    fiberAnalysis?.raw != null
  ) {
    return {
      value:
        1000 -
        fatAnalysis.raw -
        proteinAnalysis.raw -
        ashAnalysis.raw -
        fiberAnalysis.raw,
      blocked: false,
    };
  }
  return { value: 0, blocked: true };
};

export const nfegPerkgDrysubst: IFormula = (analysis, analyses) => {
  const dry = nfeDrysubst(analysis, analyses);
  return { value: dry.value * 10, blocked: dry.blocked };
};

export const smbgPerkg: IFormula = (analysis, analyses) => {
  const proteinAnalysis = fromFormulaName(analyses, "protein");
  const fatAnalysis = fromFormulaName(analyses, "fat");
  const fiberAnalysis = fromFormulaName(analyses, "fibre");
  const ashAnalysis = fromFormulaName(analyses, "ash");
  if (
    fiberAnalysis?.raw == null ||
    proteinAnalysis?.raw == null ||
    fatAnalysis?.raw == null ||
    ashAnalysis?.raw == null ||
    !analysis.productMeta?.material
  ) {
    return { value: 0, blocked: true };
  }
  const nfegts = nfeDrysubst(analysis, analyses);
  const blocked = blocking([fiberAnalysis, nfegts]);
  const digestibilityMap = digestibility(
    analysis.productMeta.material?.toString(),
  );
  return {
    value:
      (proteinAnalysis.raw * (digestibilityMap.protein / 100) * 18.9 +
        fatAnalysis.raw * (digestibilityMap.fat / 100) * 34.8 +
        fiberAnalysis.raw * (digestibilityMap.fibre / 100) * 12.2 +
        nfegts.value * (digestibilityMap.nfe / 100) * 15.5) /
      1000,
    blocked,
  };
};

export const aatgPerkg: IFormula = (analysis, analyses) => {
  const proteinAnalysis = fromFormulaName(analyses, "protein");
  if (!proteinAnalysis) {
    return { value: 0 };
  }
  const protein = gPerkgDrysubst(proteinAnalysis, analyses);
  const smb = smbgPerkg(analysis, analyses); // Maybe lookup correct analysis
  const B47 = 0.85;
  const B49 = 0.179;
  const J25 = 0.78;
  const J26 = 0.69;
  const B50 = 0.7;
  const B51 = 0.85;
  const blocked = blocking([protein, smb]);
  return {
    value: protein.value * (1 - J25) * B47 * J26 + smb.value * B49 * B50 * B51,
    blocked,
  };
};

export const nfe: IFormula = (analysis, analyses) => {
  const fat = fromFormulaName(analyses, "fett");
  const protein = fromFormulaName(analyses, "protein");
  const ash = fromFormulaName(analyses, "aska");
  const fiber = fromFormulaName(analyses, "fiber");
  const water = fromFormulaName(analyses, "vattenhalt");
  if (fat?.raw && protein?.raw && ash?.raw && fiber?.raw && water?.raw) {
    const blocked = blocking([fat, protein, ash, fiber, water]);
    return {
      value: 100 - fat.raw - protein.raw - ash.raw - fiber.raw - water.raw,
      blocked,
    };
  }
  return { value: 0 };
};

export const pbvgPerkg: IFormula = (analysis, analyses) => {
  const proteinAnalysis = fromFormulaName(analyses, "protein");
  if (!proteinAnalysis) {
    return { value: 0 };
  }
  const protein = gPerkgDrysubst(proteinAnalysis, analyses);
  const smb = smbgPerkg(analysis, analyses); // Maybe lookup correct analysis
  const J25 = 0.78;
  const B49 = 0.179;
  const blocked = blocking([protein, smb]);

  return { value: protein.value * J25 - smb.value * B49, blocked };
};

export const omsEnergy: IFormula = (analysis, analyses) => {
  const C63 = 18.9;
  const C64 = 34.8;
  const C66 = 15.5;
  const C67 = 12.2;

  const J19 = 0.76;
  const J20 = 0.75;
  const J21 = 0.3;
  const J22 = 0.89;

  const proteinAnalysis = fromFormulaName(analyses, "protein");
  if (!proteinAnalysis) {
    return { value: 0 };
  }
  const protein = gPerkgDrysubst(proteinAnalysis, analyses);
  const fatAnalysis = fromFormulaName(analyses, "fett");
  if (!fatAnalysis) {
    return { value: 0 };
  }
  const fat = gPerkgDrysubst(fatAnalysis, analyses);
  const fiberAnalysis = fromFormulaName(analyses, "fiber");
  if (!fiberAnalysis) {
    return { value: 0 };
  }
  const fiber = gPerkgDrysubst(fiberAnalysis, analyses);
  const calculatedNfe = nfegPerkgDrysubst(analysis, analyses);
  const blocked = blocking([protein, fat, fiber, calculatedNfe]);
  return {
    value:
      (protein.value * J19 * C63 +
        fat.value * J20 * C64 +
        calculatedNfe.value * J22 * C66 +
        fiber.value * J21 * C67) /
      1000,
    blocked,
  };
};

export const smbCarbGrov: IFormula = (analysis, analyses) => {
  const energy = fromFormulaName(analyses, "energy");
  const protein = fromFormulaName(analyses, "protein");
  if (
    !energy ||
    energy.raw === undefined ||
    !protein ||
    protein.raw === undefined ||
    !analysis.productMeta?.material
  ) {
    return { value: 0 };
  }
  const magicConstants = smbCarbConstants(
    analysis.productMeta.material.toString(),
  );
  const smbKoeff = (magicConstants.a + magicConstants.b * energy.raw) / 100;
  const carbs = magicConstants.c - protein.raw * 1.46;
  const blocked = blocking([energy, protein]);
  return { value: smbKoeff * carbs, blocked };
};

export const aatGrov: IFormula = (analysis, analyses) => {
  const protein = fromFormulaName(analyses, "protein");
  const smb = smbCarbGrov(analysis, analyses);
  if (!protein || protein.raw === undefined) {
    return { value: 0 };
  }
  const EPD = 0.8;
  const a = 0.65;
  const b = 0.82;
  const c = 0.179;
  const d = 0.7;
  const e = 0.85;
  const blocked = blocking([protein, smb]);
  return {
    value: protein.raw * (1 - EPD) * a * b + smb.value * c * d * e,
    blocked,
  };
};

export const pbvGrov: IFormula = (analysis, analyses) => {
  const protein = fromFormulaName(analyses, "protein");
  if (!protein || protein.raw === undefined) {
    return { value: 0 };
  }
  const smb = smbCarbGrov(analysis, analyses);
  const EPD = 0.8;
  const B49 = 0.179;
  const blocked = blocking([protein, smb]);

  return { value: protein.raw * EPD - smb.value * B49, blocked };
};

export const omsEnergyIdis: IFormula = (analysis, analyses) => {
  const energy = fromFormulaName(analyses, "energy");
  if (!energy || energy.raw === undefined) {
    return { value: 0 };
  }
  const blocked = blocking([energy]);
  return { value: +energy.raw || 0, blocked };
};

export const omsEnergyHest: IFormula = (analysis, analyses) => {
  const energy = fromFormulaName(analyses, "energy");
  if (!energy || energy.raw === undefined) {
    return { value: 0 };
  }
  const blocked = blocking([energy]);
  return { value: 1.12 * energy.raw - 1.2, blocked };
};

export const smbProtGrov: IFormula = (analysis, analyses) => {
  const protein = fromFormulaName(analyses, "protein");
  if (!protein?.raw) {
    return { value: 0 };
  }
  const smbKoeff = (93.9 - 3130 / protein.raw) / 100;
  const blocked = blocking([protein]);
  return { value: protein.raw * smbKoeff, blocked };
};

export const protEnergyQuota: IFormula = (analysis, analyses) => {
  const rawProtein = fromFormulaName(analyses, "protein");
  const rawEnergy = fromFormulaName(analyses, "energy");
  if (!(rawProtein && rawEnergy)) {
    return { value: 0 };
  }
  const energy = omsEnergyHest(rawEnergy, analyses);
  const protein = smbProtGrov(rawProtein, analyses);
  const blocked = blocking([protein, energy]);
  return { value: protein.value / energy.value, blocked };
};

// const sucroseCalc: IFormula = (analysis, analyses) => {
//   const sugar = fromFormulaName(analyses, "sugar");
//   const glucose = fromFormulaName(analyses, "glucose");
//   const fructose = fromFormulaName(analyses, "fructose");
//   const fructan = fromFormulaName(analyses, "fructan");
//   if (
//     sugar?.raw === undefined ||
//     glucose?.raw === undefined ||
//     fructose?.raw === undefined ||
//     fructan?.raw === undefined
//   ) {
//     return { value: 0 };
//   }
//   const blocked = blocking([sugar, glucose, fructose, fructan]);
//   return { value: +sugar.raw - (+glucose.raw + +fructose.raw + +fructan.raw), blocked };
// };

const sucroseCorr: IFormula = (analysis, analyses) => {
  // const sucrose = sucroseCalc(analysis, analyses);
  // const blocked = blocking([sucrose]);
  // return { value: sucrose.value < 0 ? -sucrose.value : 0, blocked };
  const sugar = fromFormulaName(analyses, "sugar");
  const glucose = fromFormulaName(analyses, "glucose");
  const fructose = fromFormulaName(analyses, "fructose");
  const fructan = fromFormulaName(analyses, "fructan");
  const sucrose = fromFormulaName(analyses, "sucrose");
  if (
    sugar?.raw === undefined ||
    glucose?.raw === undefined ||
    fructose?.raw === undefined ||
    fructan?.raw === undefined ||
    sucrose?.raw === undefined
  ) {
    return { value: 0 };
  }
  const blocked = blocking([sugar, glucose, fructose, fructan]);
  return {
    value:
      (+glucose.raw +
        +fructose.raw +
        +fructan.raw +
        +sucrose?.raw -
        +sugar.raw) /
      2,
    blocked,
  };
};

export const sugar: IFormula = (analysis, analyses) => {
  const calcSugar = fromFormulaName(analyses, "sugar");
  const sucroseC = sucroseCorr(analysis, analyses);
  if (calcSugar?.raw === undefined) {
    return { value: 0 };
  }

  const blocked = blocking([calcSugar, sucroseC]);
  return { value: +calcSugar.raw + sucroseC.value, blocked };
};

const sumPos = (arr: (number | undefined)[]) =>
  (<number[]>arr.filter((n) => n && n > 0)).reduce((p, c) => +p + +c, 0);

export const glucose: IFormula = (analysis, analyses) => {
  const glucose = fromFormulaName(analyses, "glucose");
  const fructose = fromFormulaName(analyses, "fructose");
  const fructan = fromFormulaName(analyses, "fructan");
  const sucrose = fromFormulaName(analyses, "sucrose");
  const sucroseC = sucroseCorr(analysis, analyses);
  if (glucose?.raw === undefined) {
    return { value: 0 };
  }
  const sum = sumPos([glucose.raw, fructose?.raw, fructan?.raw, sucrose?.raw]);
  if (sum === 0) {
    return { value: 0 };
  }
  const blocked = blocking([glucose, fructose, fructan, sucrose, sucroseC]);
  return {
    value: +glucose.raw - sucroseC.value * (+glucose.raw / sum),
    blocked,
  };
};

export const fructose: IFormula = (analysis, analyses) => {
  const glucose = fromFormulaName(analyses, "glucose");
  const fructose = fromFormulaName(analyses, "fructose");
  const fructan = fromFormulaName(analyses, "fructan");
  const sucrose = fromFormulaName(analyses, "sucrose");
  const sucroseC = sucroseCorr(analysis, analyses);
  if (fructose?.raw === undefined) {
    return { value: 0 };
  }
  const sum = sumPos([glucose?.raw, fructose?.raw, fructan?.raw, sucrose?.raw]);
  if (sum === 0) {
    return { value: 0 };
  }
  const blocked = blocking([glucose, fructan, fructose, sucrose, sucroseC]);
  return {
    value: +fructose.raw - sucroseC.value * (+fructose.raw / sum),
    blocked,
  };
};

export const fructan: IFormula = (analysis, analyses) => {
  const glucose = fromFormulaName(analyses, "glucose");
  const fructose = fromFormulaName(analyses, "fructose");
  const fructan = fromFormulaName(analyses, "fructan");
  const sucrose = fromFormulaName(analyses, "sucrose");
  const sucroseC = sucroseCorr(analysis, analyses);
  if (fructan?.raw === undefined) {
    return { value: 0 };
  }
  const sum = sumPos([glucose?.raw, fructose?.raw, fructan?.raw, sucrose?.raw]);
  if (sum === 0) {
    return { value: 0 };
  }
  const blocked = blocking([glucose, fructan, fructose, sucrose, sucroseC]);
  return {
    value: +fructan.raw - sucroseC.value * (+fructan.raw / sum),
    blocked,
  };
};

export const sucrose: IFormula = (analysis, analyses) => {
  const glucose = fromFormulaName(analyses, "glucose");
  const fructose = fromFormulaName(analyses, "fructose");
  const fructan = fromFormulaName(analyses, "fructan");
  const sucrose = fromFormulaName(analyses, "sucrose");
  const sucroseC = sucroseCorr(analysis, analyses);
  if (sucrose?.raw === undefined) {
    return { value: 0 };
  }
  const sum = sumPos([glucose?.raw, fructose?.raw, fructan?.raw, sucrose?.raw]);
  if (sum === 0) {
    return { value: 0 };
  }
  const blocked = blocking([glucose, fructan, fructose, sucrose, sucroseC]);
  return {
    value: +sucrose.raw - sucroseC.value * (+sucrose.raw / sum),
    blocked,
  };
};

export const pcr: IFormula = (analysis, analyses) => {
  const lowerLimit = <number>analysis.meta?.lowerlimit;
  const breakpoint = <number>analysis.meta?.breakpoint;
  if (analysis.raw === undefined || !lowerLimit || !breakpoint) {
    return { value: 0 };
  }
  if (analysis.raw < lowerLimit) {
    return { value: 0, blocking: true };
  }
  return { value: analysis.raw >= breakpoint ? 0 : 1 };
};

export const cab: IFormula = (analysis, analyses, otherAnalyses) => {
  if (!otherAnalyses) {
    return { value: 0 };
  }
  const k = fromFormulaName(otherAnalyses, "K");
  const na = fromFormulaName(otherAnalyses, "Na");
  const cl = fromFormulaName(analyses, "cl");
  const s = fromFormulaName(otherAnalyses, "S");
  if (k?.raw == null || na?.raw == null || cl?.raw == null || s?.raw == null) {
    return { value: 0 };
  }
  return {
    value:
      (k.raw / 1000 / 39.1 +
        na.raw / 1000 / 23 -
        (cl.raw / 35.5 + ((s.raw / 1000) * 2) / 32)) *
      1000,
  };
};

export const scp: IFormula = (analysis, analyses, otherAnalyses) => {
  if (!otherAnalyses) {
    return { value: 0 };
  }
  const protein = fromFormulaName(otherAnalyses, "protein");
  const smbprot = analysis.raw;
  if (protein?.raw === undefined || smbprot === undefined) {
    return { value: 0 };
  }
  return { value: (smbprot / protein.raw) * 1000 };
};

export const nh4n: IFormula = (analysis, analyses, otherAnalyses) => {
  if (!otherAnalyses) {
    return { value: 0 };
  }
  const protein = fromFormulaName(otherAnalyses, "protein");
  const nh4 = analysis.raw;
  if (protein?.raw === undefined || nh4 === undefined) {
    return { value: 0 };
  }
  return { value: (nh4 / (protein.raw / 6.25)) * 1000 };
};

export const omd: IFormula = (analysis, analyses, otherAnalyses) => {
  const vos = fromFormulaName(analyses, "vos");
  if (vos?.raw === undefined) {
    return { value: 0 };
  }
  const blocked = blocking([vos]);
  return { value: 0.9 * vos.raw - 2.0, blocked };
};

export const QuotaCaP: IFormula = (analysis, analyses) => {
  const Ca = fromFormulaName(analyses, "Ca");
  const P = fromFormulaName(analyses, "P");
  const blocked = blocking([Ca, P]);
  if (Ca?.raw === undefined || P?.raw === undefined) {
    return { value: 0 };
  }
  return { value: Ca?.raw / P?.raw, blocked };
};

export const butyricacid: IFormula = (analysis, analyses) => {
  return { value: 0 };
};

export const epg: IFormula = (analysis, analyses) => {
  if (!analysis || (!analysis.raw && analysis.raw !== 0)) {
    return { value: 0, minlimit: false };
  }
  if (!analysis.multiple) {
    return { value: 0, minlimit: false };
  }
  if (analysis.raw > 0 && analysis.raw < 1) {
    return { value: 30, minlimit: true }; // "<30";
  }
  const adjusted = analysis.raw * analysis.multiple;
  if (analysis.meta?.strictValue) {
    return { value: adjusted, minlimit: false }; // "<" + adjusted;
  }
  let rounded = 0;
  if (adjusted < 99) {
    rounded = Math.floor(adjusted / 10) * 10;
  } else {
    rounded = (Math.round(adjusted / 50) / 2) * 100;
  }
  return { value: rounded, minlimit: false };
};

export const percent: IFormula = (analysis, analyses) => {
  let percentOfValue = analysis.percentOfValue;
  let blocked = false;
  if (!percentOfValue && analysis.percentOf) {
    const percentOf = fromFormulaName(analyses, analysis.percentOf);
    if (percentOf) {
      percentOfValue = percentOf.raw;
    }
    blocked = false; //blocking([percentOf]);
  }
  if (!percentOfValue) {
    return { value: 0, blocked };
  }
  if (percentOfValue <= 0) {
    return { value: 0, blocked };
  }
  if (analysis.raw === undefined) {
    return { value: 0, blocked };
  }
  const percent = +((analysis.raw / percentOfValue) * 100).toFixed();
  return { value: percent, blocked };
};

export const ostertagia: IFormula = (analysis, analyses) => {
  const pos1 = fromFormulaName(analyses, "pos1");
  const pos2 = fromFormulaName(analyses, "pos2");
  const neg1 = fromFormulaName(analyses, "neg1");
  const neg2 = fromFormulaName(analyses, "neg2");
  const blocked = blocking([pos1, pos2, neg1, neg2]);
  if (!pos1?.raw || !pos2?.raw || !neg1?.raw || !neg2?.raw || !analysis.raw) {
    return { value: 0, blocked };
  }
  const pos = (+pos1.raw + +pos2.raw) / 2.0;
  const neg = (+neg1.raw + +neg2.raw) / 2.0;
  const value = (+analysis.raw - neg) / (pos - neg);
  return { value, blocked };
};

export const elisaCtrl: IFormula = (analysis, analyses) => {
  const controlName = analysis.meta?.ctrl;
  if (!controlName) {
    return { value: 0 };
  }
  const ctrl = fromFormulaName(analyses, <string>controlName);
  if (!ctrl?.raw || !analysis.raw) {
    return { value: 0 };
  }
  let blocked = false;
  const avg = (+ctrl.raw + +analysis.raw) / 2.0;
  const diff = Math.abs(avg - analysis.raw);
  if (diff / avg > 0.25) {
    blocked = true;
  }

  return { value: +analysis.raw, blocked };
};
