import { decamelize } from "humps";
import type {
  Customer,
  CustomerDuplicate,
  HiddenDuplicate,
  ICustomerWithJournalStatistics,
  SourceCustomer,
} from "../models/customer";
import { ApiBase } from "./apiBase";

class CustomerApi extends ApiBase {
  abortSearchController: AbortController;
  async loadBlacklist() {
    return this.get<Customer[]>("customer/load_blacklist");
  }
  async fullSearch(searchTerm: string, page: number) {
    this.abortSearchController?.abort();
    this.abortSearchController = new AbortController();
    return this.post<{ customers: Customer[]; count: number }>(
      "customer/full_search",
      { searchTerm, offset: page * 20 },
      { signal: this.abortSearchController.signal },
    );
  }

  async quickSearch(searchTerm: string, page: number) {
    this.abortSearchController?.abort();
    this.abortSearchController = new AbortController();
    return this.post<{ customers: Customer[]; count: number }>(
      "customer/quick_search",
      { searchTerm, offset: page * 20 },
      { signal: this.abortSearchController.signal },
    );
  }
  async update(customer: Customer) {
    return this.post<Customer>("customer/update", {
      customer: customer,
      note: customer.note,
    });
  }
  async create(customer: Customer) {
    return this.post<Customer>("customer/add2", { customer: customer });
  }
  async updateMemberships(customer: Customer) {
    return this.post<Customer>("customer/update_memberships", {
      customer_id: customer.id,
    });
  }
  async getBroken() {
    return this.get<Customer[]>("customer/get_broken");
  }

  async searchGod(seNumber: number) {
    return this.get<SourceCustomer>("customer/search_god", {
      seNumber: seNumber,
    });
  }

  async resetGod(customer: Customer) {
    return this.post<Customer>("customer/reset_god", {
      customer_id: customer.id,
    });
  }

  async fetchDuplicates(field: string) {
    return this.get<CustomerDuplicate[]>("customer/fetch_duplicates", {
      field: decamelize(field),
    });
  }

  async fetchCustomers(customer: Partial<Customer>) {
    return this.get<ICustomerWithJournalStatistics[]>("customer/get", {
      customer,
    });
  }

  async mergeCustomers(customerId: number, toMerge: number[]) {
    return this.post<Customer>("customer/merge_duplicates", {
      customerId: customerId,
      toMerge: toMerge,
    });
  }

  async fetchHiddenDuplicates() {
    return this.get<HiddenDuplicate[]>("customer/hidden_duplicates");
  }
}

export default new CustomerApi();
