import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import reducer from "./rootReducer";
declare const module: {
  hot: { accept: (foo: string, callback: () => void) => void };
};

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: true,
    }),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat([Sentry.createReduxEnhancer()]),
});

export default store;
export type AppDispatch = typeof store.dispatch;
