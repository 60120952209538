import type { Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    red: Palette["primary"];
  }

  interface PaletteOptions {
    red?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    red: true;
  }
}

export const addRed = (theme: Theme) =>
  theme.palette.augmentColor({
    color: { main: "#ef5350" },
    name: "red",
  });
