import {
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import Papa from "papaparse";
import { calculateAnalyses } from "../analysisHelper";
import readAnalysisApi from "../apis/readAnalysisApi";
import { arrayInsert } from "../helpers/arrayHelpers";
import { fullLabnumber } from "../helpers/labnumberHelper";
import { Als } from "../helpers/resultUpload/alsHelper";
import { Icp } from "../helpers/resultUpload/icpHelper";
import { Nir } from "../helpers/resultUpload/nirHelper";
import {
  FileFormats,
  type ParseResult,
  type UploadedRow,
  applyResult,
  filterResult,
  idColumn,
  uploadRowFromAgrilab,
  uploadRowsFromPapa,
} from "../helpers/resultUploadHelper";
import type { IAnalysis } from "../models/analysis";
import type { AnalysisRow } from "../views/resultUpload/analysisRow";

enum UploadError {
  ReadError = "READ_ERROR",
  FetchError = "FETCH_ERROR",
}

interface ResultUploadSliceState {
  uploading: boolean;
  format: FileFormats;
  parseResult?: ParseResult;
  extraFields: { [key: string]: string };
  loaded: boolean;
  extraColumnKeys: string[];
  error?: UploadError;
  rows?: AnalysisRow[];
  saving: boolean;
}

const initialState: ResultUploadSliceState = {
  uploading: false,
  format: FileFormats.NIR,
  extraFields: {},
  loaded: false,
  extraColumnKeys: [],
  saving: false,
};
type ThunkApi<RejectValue = unknown> = {
  state: { resultUpload: ResultUploadSliceState };
  rejectValue: RejectValue;
};

export const uploadFile = createAsyncThunk<void, File, ThunkApi>(
  "resultUpload/upload",
  (file: File, thunkApi) => {
    const state = thunkApi.getState();
    const config: Papa.ParseLocalConfig<
      { [key: string]: string | number },
      File
    > = {
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        thunkApi.dispatch(parseComplete(result));
      },
      skipEmptyLines: "greedy",
      transform: (value) => value.replace(",", "."),
    };
    if (state.resultUpload.format === FileFormats.ALS) {
      config.beforeFirstChunk = Als.clean;
    }
    if (state.resultUpload.format === FileFormats.ICP) {
      config.beforeFirstChunk = Icp.clean;
      config.transformHeader = Icp.cleanHeader;
    }
    if (state.resultUpload.format === FileFormats.NIR) {
      config.transformHeader = Nir.cleanHeader;
    }
    Papa.parse(file, config);
  },
);

const parseComplete = createAsyncThunk<ParseResult, ParseResult, ThunkApi>(
  "resultUpload/parseComplete",
  (result, thunkApi) => {
    const state = thunkApi.getState();
    const idColumnName = idColumn(state.resultUpload.format);
    const filteredResult = filterResult(result, state.resultUpload.format);
    const parsedResult = uploadRowsFromPapa(
      filteredResult,
      idColumnName,
      state.resultUpload.format,
    );

    thunkApi.dispatch(uploadComplete(parsedResult));

    return result;
  },
);

export const parseAgriComplete = createAsyncThunk<
  void,
  Record<string, string | number>[],
  ThunkApi
>("resultUpload/parseAgriComplete", (result, thunkApi) => {
  const weights = result.shift();
  if (!weights) {
    return;
  }
  const parsedResult = result.map((row) => uploadRowFromAgrilab(row, weights));
  thunkApi.dispatch(uploadComplete(parsedResult));
});

const uploadComplete = createAsyncThunk<
  { rows: AnalysisRow[]; extras: string[] },
  UploadedRow[],
  ThunkApi<UploadError>
>("resultUpload/uploadComplete", async (result: UploadedRow[], thunkApi) => {
  const labIds = result.map((row) => row.labnumber ?? row.well);
  if (labIds.some((id) => id == null)) {
    return thunkApi.rejectWithValue(UploadError.ReadError);
  }
  try {
    const state = thunkApi.getState();
    const loadResult =
      state.resultUpload.format !== FileFormats.PCR
        ? await readAnalysisApi.getAnalysesBatch2(labIds as number[])
        : await readAnalysisApi.getPlateAnalyses(
            state.resultUpload.extraFields.platename,
            labIds as string[],
          );
    let rows: AnalysisRow[] = loadResult.rows.map((row) => {
      return {
        ...row,
        labnumber: row.labnumber,
        well: row.well,
        analyses: row.analyses ?? [],
        selected: true,
        extra: row.extra,
      };
    });
    const extras: string[] = [];
    for (const row of rows) {
      if (row.extra) {
        for (const extra of Object.keys(row.extra)) {
          if (extras.indexOf(extra) < 0) {
            extras.push(extra);
          }
        }
      }
    }
    rows = rows.map((row) =>
      applyResult(row, result, state.resultUpload.format),
    );
    rows = rows.concat(
      loadResult.errors.map((e) => {
        return {
          labnumber: fullLabnumber(e.labnumber),
          error: e.error,
          analyses: [],
        };
      }),
    );
    return { rows, extras };
  } catch (error) {
    return thunkApi.rejectWithValue(UploadError.FetchError);
  }
});

export const saveResult = createAsyncThunk<AnalysisRow[], void, ThunkApi>(
  "resultUpload/save",
  async (_f, thunkApi) => {
    const state = thunkApi.getState();
    if (!state.resultUpload.rows) {
      return thunkApi.rejectWithValue("");
    }
    const rowsToSave = state.resultUpload.rows
      .filter((r) => r.selected)
      .map((r) => {
        return { labnumber: r.labnumber, analyses: r.analyses };
      });
    const result = await readAnalysisApi.saveAnalyses(rowsToSave, true);
    const updatedRows = state.resultUpload.rows.map((row) => {
      return {
        ...row,
        status: result.find((r) => r.labnumber === row.labnumber),
      };
    });
    return updatedRows;
  },
);

const resultUploadSlice = createSlice({
  name: "resultUpload",
  initialState,
  reducers: {
    blockAnalysis(
      state,
      action: PayloadAction<{
        row: AnalysisRow;
        analysis: IAnalysis;
        blocked: boolean;
      }>,
    ) {
      if (!state.rows) {
        return;
      }
      const index = state.rows.findIndex(
        (r) => r.labnumber === action.payload.row.labnumber,
      );
      const r = state.rows[index];
      const analysisIndex = r.analyses.findIndex(
        (a) => a.id === action.payload.analysis.id,
      );
      const analyses = calculateAnalyses(
        arrayInsert(
          r.analyses,
          {
            ...action.payload.analysis,
            details: {
              ...action.payload.analysis.details,
              blocked: action.payload.blocked,
            },
          },
          analysisIndex,
        ),
        r.analyses,
      );
      state.rows = arrayInsert(state.rows, { ...r, analyses }, index);
    },
    selectRow(state, action: PayloadAction<AnalysisRow>) {
      if (!state.rows) {
        return state;
      }
      const index = state.rows.findIndex(
        (r) => r.labnumber === action.payload.labnumber,
      );
      const r = state.rows[index];
      state.rows = arrayInsert(
        state.rows,
        { ...r, selected: !r.selected },
        index,
      );
      return state;
    },
    setFormat(state, action: PayloadAction<FileFormats>) {
      state.format = FileFormats[action.payload];
      state.extraFields = {};
    },
    setExtraField(
      state,
      action: PayloadAction<{ key: string; value: string }>,
    ) {
      state.extraFields[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(uploadFile.pending, (state) => {
        state.uploading = true;
      })
      .addCase(parseComplete.pending, (state, action) => {
        state.parseResult = action.meta.arg;
      })
      .addCase(uploadComplete.rejected, (state, action) => {
        state.uploading = false;
        state.loaded = false;
        state.error = action.payload;
      })
      .addCase(uploadComplete.fulfilled, (state, action) => {
        state.uploading = false;
        state.loaded = true;
        state.rows = action.payload.rows;
        state.extraColumnKeys = action.payload.extras;
      })
      .addCase(saveResult.pending, (state) => {
        state.saving = true;
      })
      .addCase(saveResult.fulfilled, (state, action) => {
        state.saving = false;
        state.rows = action.payload;
      })
      .addCase(saveResult.rejected, (state) => {
        state.saving = false;
      }),
});

export const { blockAnalysis, setFormat, setExtraField, selectRow } =
  resultUploadSlice.actions;

export default resultUploadSlice.reducer;
