import type { User } from "../models/user";
import { ApiBase } from "./apiBase";

type AuthResponse = {
  token: string;
  user: User;
};

class AuthApi extends ApiBase {
  async validateToken(token: string) {
    return this.get<{ username: string; user: User }>("auth/validate_token", {
      token,
    });
  }
  async login(username: string, password: string) {
    return this.post<AuthResponse>("auth/login", { username, password });
  }

  async logout(token: string) {
    return this.get("auth/logout", { token });
  }

  async changePassword(
    oldPassword: string,
    newPassword: string,
    passwordConfirmation: string,
  ) {
    return this.post<User>("auth/update_password", {
      oldPassword,
      newPassword,
      passwordConfirmation,
    });
  }
}

export default new AuthApi();
