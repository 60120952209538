import { type PropsWithChildren, createContext, useCallback, useState, useEffect } from "react";
import type { User } from "../models/user";
import authApi from "../apis/authApi";
import * as Sentry from "@sentry/browser";
import { useLocalStorage } from "usehooks-ts";

type UserContextType = {
  currentUser: User | undefined;
  validatingToken: boolean;
  logout(): void;
  login(user: User, token: string): void;
  username: string;
};

export const UserContext = createContext<UserContextType>({
  currentUser: undefined,
  validatingToken: false,
  logout: () => { },
  login: () => { },
  username: "",
});

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [validatingToken, setValidatingToken] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [token, setToken, removeToken] = useLocalStorage<string>("token", "");
  const [username, setUsername,] = useLocalStorage<string>("username", "");
  const login = useCallback((user: User, token: string) => {
    setCurrentUser(user)
    Sentry.setUser({
      username: user.name,
      id: user.id?.toString(),
    })
    setToken(token)
    setUsername(user.name)
  }, [setUsername, setToken])
  const logout = useCallback(() => {
    setCurrentUser(undefined)
    Sentry.setUser(null)
    removeToken()
  }, [removeToken])
  const validateToken = useCallback(async () => {
    setValidatingToken(true);
    if (!token) {
      logout()
      return setValidatingToken(false);
    }
    try {
      const response = await authApi.validateToken(token);

      if (response.user) {
        login(response.user, token);
      }
    } catch (e) {
      logout();
    }

    setValidatingToken(false);
  }, [login, logout, token]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  const context = {
    currentUser,
    login,
    logout,
    username,
    validatingToken
  }

  return (<UserContext.Provider value={context}>{children}</UserContext.Provider>)
}