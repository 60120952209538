import type { DateTime } from "luxon";
import type { CheckResult, SendResult } from "../helpers/alsHelpers";
import { ApiBase } from "./apiBase";

class AlsCheckApiService extends ApiBase {
  async check(labnumber: number) {
    return this.get<CheckResult>("als/check", { labnumber });
  }

  async generate(journals: string[], date: DateTime) {
    return this.post<Response>(
      "als/generate_waybill",
      { journals: journals, date: date.toISODate() },
      { json: false },
    );
  }

  async toSend(date: DateTime) {
    return this.post<SendResult>("als/to_send", { date: date.toISODate() });
  }
}

export default new AlsCheckApiService();
