import type { Message } from "../models/message";
import type { MessageTag } from "../models/messageTag";
import { ApiBase } from "./apiBase";

class MessageApi extends ApiBase {
  async saveComment(analysisGroupId: number, note: string) {
    return this.post<Message[]>("message/add_for_analysis_group", {
      analysisGroupId,
      note,
    });
  }
  async deleteMessage(id: number) {
    return this.delete("message/delete", { id });
  }
  async read(tags: MessageTag[]) {
    return this.post<Message[]>("message/read", { search: { tags: tags } });
  }
  async add(message: Message) {
    return this.post<Message>("message/add", {
      message: { tags: message.messageTags, ...message },
    });
  }
  async save(message: Message) {
    return this.post<Message>("message/save", { message });
  }
  async saveInternalMessage(journalGroupId: number, message: string) {
    return this.post<Message>("journals/save_internal_message", {
      journalId: journalGroupId,
      message,
    });
  }
  async saveCustomerMessage(journalGroupId: number, message: string) {
    return this.post<Message>("journals/save_customer_message", {
      journalId: journalGroupId,
      message,
    });
  }
}

export default new MessageApi();
