import { DateTime } from "luxon";
import type { ValidationResult } from "../models/framework/validationResult";

export function trimLabnumber(labnumber: number) {
  let calculatedLabnumber = labnumber;
  if (calculatedLabnumber > 10000000) {
    if (
      calculatedLabnumber.toString().slice(0, 2) ===
      (DateTime.local().year - 2000).toString()
    ) {
      calculatedLabnumber = +calculatedLabnumber.toString().slice(2);
    }
  }
  return calculatedLabnumber;
}

export function fullLabnumber(labnumber: number) {
  const year = DateTime.local().year;
  if (year < 2022) {
    if (labnumber >= 1000000 && labnumber < 10000000) {
      return labnumber + (year - 2000) * 10000000;
    }
    return labnumber > 1000000
      ? labnumber
      : labnumber + (year - 2000) * 1000000;
  } else {
    return labnumber > 10000000
      ? labnumber
      : labnumber + (year - 2000) * 10000000;
  }
}

export function labnumberEquals(labnumber1: number, labnumber2: number) {
  return (
    labnumber1 === labnumber2 ||
    trimLabnumber(labnumber1) === labnumber2 ||
    labnumber1 === trimLabnumber(labnumber2)
  );
}

export function validate(labnumber: string): ValidationResult {
  let valid = true;
  let validationMessage: string | undefined;
  if (labnumber.length > 9) {
    valid = false;
    validationMessage = "Ogiltigt labbnummer, för långt";
  }
  return { valid, validationMessage };
}
